import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useMemo, useEffect, useState } from 'react';
// @mui
import {
    Box,
    Card,
    Table,
    Stack,
    Paper,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Alert,
    Button,
    useTheme, InputAdornment, IconButton, Input,
} from '@mui/material'
import axios from 'axios';
// components
import { useParams } from 'react-router-dom';
import Scrollbar from '../../../components/scrollbar';
// sections
import { ItemListHead } from './item';
import { API_URL, APP_NAME, TITLE_SEPERATOR } from '../../../hooks/globals';
import { useAuth } from '../../../auth/Authentication';
import StyledBox from '../../../theme/artist/overrides/StyledBox';
import useGetSkeletons from '../../../hooks/useGetSkeletons';
import useResponsive from '../../../hooks/useResponsive';
import useConsoleLog from '../../../hooks/useConsoleLog';
import ShowMoreInfo from './ShowMoreInfo';
import SurveryButton from './SurveyButton';
import "../../../scss/orders.scss";
import Iconify from '../../../components/iconify';
import SecondaryEmail from "./SecondaryEmail";
// ----------------------------------------------------------------------


export default function OrderseListingLayout(props) {

    function descendingComparator(a, b, orderBy) {
        let theA = a[orderBy];
        let theB = b[orderBy];

        if (orderBy === 'createdAt') {
            theA = new Date(a[orderBy]?.split("-").reverse().join("-"));
            theB = new Date(b[orderBy]?.split("-").reverse().join("-"));
        }

        if (theB < theA) {
            return -1;
        }
        if (theB > theA) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function applySortFilter(array, comparator, query) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filter(array, (_item) => {
                return _item[props.objectKeys[1]]?.toLowerCase().indexOf(query.toLowerCase()) !== -1
            });

        }
        return stabilizedThis.map((el) => el[0]);
    }

    const [axiosError, setAxiosError] = useState({ message: '', showLink: false });

    const log = useConsoleLog;

    const auth = useAuth();

    const [showMore, setShowMore] = useState();

    const [open, setOpen] = useState(null);


    const [page, setPage] = useState(0);

    const theme = useTheme();

    const [order, setOrder] = useState('desc');

    const [selected, setSelected] = useState({});

    const [orderBy,] = useState('CreatedDate');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [itemList, setItemList] = useState({});

    const [filteredItems, setFilteredItems] = useState({});

    const [isNotFound, setIsNotFound] = useState(false);

    const [spotifyBearer, setSpotifyBearer] = useState();

    const [timeoutIds, setTimeoutIds] = useState([]);

    const [loading, setLoading] = useState(props.loading);

    const [dataPage, setDataPage] = useState(1);


    const { userId, userEmail } = useParams();

    const statusColors = useMemo(() => ({
        Unclaimed: "#ab7e02",
        "Pending pickup": "#ab7e02",
        "Waiting for": "#1ABDEF",
        "Pending placement": "#1ABDEF",
        Completed: "#1DD263",
        "Placement made": "#1DD263",
        Pitching: "#FFA500",
        Refunded: "#1E90FF",
        Stopped: "#ff0000",
        Restricted: "#1E90FF",
        Running: "#e6d819",
        Optimizing: "#759e05",
        Submitted: "#052b9e",
        "Near Complete": "#085718",
        "Partly refunded": "#eb2f5b",
        "TikTok Transfer": "#080405",
        "Transfer TikTok": "#080405",
        "Budget Saved": "#2548e6",
        "Idle client": "#FFC0CB",
        "Pre submission FAILED": "#325ea8",
        "Pre submission Accepted": "#32a8a4",
        "transfered": "#848c8c",
        "Instagram Transfer": "#F0B25C",
        "Transfer Instagram": "#F0B25C",
        "Spotify Transfer": "#1DD263",
        'YouTube Transfer': "#1DD263",
        'Transfer YouTube': "#1DD263",
        'Transfer Youtube': "#1DD263",
        "Insufficient": "#b30f0f",
        "Insufficient Information": "#b30f0f",
        "Transfer TikTok Sound Promotion": "#080405",
        "Transfer TikTok Video and Account Promotion": "#080405",
        "Setting Up": "#FFA500",
        "Finished": "#1DD263",
        "On hold": "#FFEDA6",
    }));

    const [getSkeletons] = useGetSkeletons();

    const isOnSmallScreen = useResponsive("down", "md", "lg");

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const [currentEmail, setCurrentEmail] = useState();
    const reset = (email) => {
        console.log(email)
        setCurrentEmail(email)
        setPage(0)
        setItemList((prevState) => {
            return {
                ...prevState,
                [email]: []
            }
        })
        setFilteredItems((prevState) => {
            return {
                ...prevState,
                [email]: []
            }
        })
        setLoading(() => true)
        setDataPage(() => 1)
    }

    const initItems = (email) => {
        log('email obj');
        log(email);
        setAxiosError({ message: '', showLink: false });
        const url = userId ? `${API_URL}${props.apiAdminPath}/${userId}/${email.email}` : `${API_URL}${props.apiPath}`;

        const config = {
            method: 'get',
            url,
            headers: {
                'Authorization': `Bearer ${auth.userToken}`
            }
        };

        axios(config).then((response) => {
            const result = response.data;
            const theItems = [...Object.values(result.orders)];
            props.onSetTreesIndi(result.trees_planted);

            log("theItems");
            log(theItems);
            log({ [email]: [...theItems] });

            setItems(email, theItems);

            setIsNotFound(!filteredItems?.length && !!filterName);
            setLoading(() => false)

            return response
        }).catch((error) => {
            if (error?.response?.status !== 404 && filteredItems[currentEmail]?.length === 0) {
                setAxiosError({
                    message: 'We currently experience a bug on our CRM system. For this reason, we can’t show accurate data in your dashboard. This issue will be solved as soon as we can!',
                    showLink: false
                });
            }
            log(error);
            setLoading(() => false)
        }).finally(() => {
        });
    }


    const getSpotifyBearer = () => {
        const config = {
            method: 'get',
            url: `${API_URL}artist/spotify-bearer`,
            headers: {
                'Authorization': `Bearer ${auth.userToken}`
            }
        };

        axios(config).then((response) => {
            log("Spotify bearer");
            log(response.data);
            if (response.data?.access_token) {
                auth.updateUser({ ...auth.user, spotify_access_token: response.data.access_token });
            }
        })
            .catch((error) => {
                log("Spotify bearer err");
                log(error);
            })
            .finally(() => {
            });
    }

    // Function that downloads PDF
    const download = (data, name) => {
        const url = URL.createObjectURL(data);
        const a = document.createElement('a');
        a.download = name;
        a.href = url;
        a.target = '_self';

        a.click();
    }

    const handleDownload = (id, name) => {
        setAxiosError({ message: '', showLink: false });
        const url = `${API_URL}artist/invoices/${id}/download`;

        const config = {
            method: 'get',
            url,
            responseType: 'blob',
            headers: {
                'Authorization': `Bearer ${auth.userToken}`
            }
        };

        axios(config).then((response) => {
            log(response);
            download(response.data, name);
        })
            .catch((error) => {
                log(error);
            });
    }

    const handleShowMore = (id) => {
        if (id === showMore) {
            setShowMore(null);
        } else {
            setShowMore(id);
        }
    }


    const convertDate = (date) => {
        const dateObj = new Date(date);

        // Get month name
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const monthName = monthNames[dateObj.getMonth()];

        // Get day of the month
        const day = dateObj.getDate();

        // Get year
        const year = dateObj.getFullYear();

        // Create formatted date string
        const formattedDate = `${monthName} ${day}, ${year}`;

        return formattedDate;
    }

    function limitToTwoWords(str) {
        return str.split(' ').slice(0, 2).join(' ');
    }

    const handleSearch = (event) => {
        const filterValue = event.target.value.toLowerCase();
        let filteredResults = [];

        // Filter items based on search criteria
        const items = Object.values(itemList)[0][0]

        if (filterValue) {
            filteredResults = items.filter(item => {
                // Check if any of the fields match the filter value
                const invoiceSamCartOrderNumber = item.InvoiceSamCartOrderNumber__c ? item.InvoiceSamCartOrderNumber__c.toLowerCase() : '';
                const spotifyTrackTitle = item.SpotifyTrackTitle__c ? item.SpotifyTrackTitle__c.toLowerCase() : '';
                const campaignManager = item.Owner.Name ? item.Owner.Name.toLowerCase() : '';

                // Check if any of the fields match the filter value
                return (
                    (invoiceSamCartOrderNumber.includes(filterValue)) ||
                    (spotifyTrackTitle.includes(filterValue)) ||
                    (campaignManager.includes(filterValue))
                );
            });
        } else {
            // If no filter value provided, display all items
            setItems(currentEmail, items);
            return;
        }

        setFilteredItems({ '[object Object]': filteredResults });
        setPage(0);
    };

    const setItems = (email, items) => {
        setItemList((prevState) => {
            if (prevState?.[email]) {
                log('prevState?.[email]');
                log(prevState?.[email]);
                const arrayCopy = [...prevState[email]]
                arrayCopy.push([...items])
                return {
                    ...prevState,
                    [email]: arrayCopy
                }
            }

            return {
                [email]: items
            }
        });

        setFilteredItems((prevState) => {
            if (prevState?.[email]) {
                const arrayCopy = [...prevState[email]]
                arrayCopy.push(...applySortFilter(items, getComparator(order, orderBy), filterName))
                return {
                    ...prevState,
                    [email]: arrayCopy
                }
            }

            return {
                ...prevState,
                [email]: applySortFilter(items, getComparator(order, orderBy), filterName)
            }
        });
    }

    useEffect(() => {
        getSpotifyBearer();
    }, [filterName, order, orderBy]);
    return (
        <>
            <Helmet>
                <link rel='stylesheet' href='/css/order-listing-layout.css' />
            </Helmet>
            <StyledBox>
                <Typography variant="h1"
                    sx={{ color: theme.palette.common.black }}>{!userId ? `Your account` : `Viewing artist account`}</Typography>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
                    <Typography variant="h4" gutterBottom sx={{ color: theme.palette.common.black }}>
                        {userId ? props.adminPageTitle + userEmail : props.pageTitle}
                    </Typography>
                </Stack>
                <Box className="secondary-email-display">
                    <SecondaryEmail props={props} setCurrentEmail={setCurrentEmail} loading={setLoading}
                        reset={reset} refreshOrders={initItems} />
                </Box>
                <Input
                    placeholder="Search orders..."
                    endAdornment={
                        <InputAdornment position="end">
                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                    }
                    onChange={handleSearch}
                    sx={{ mr: 1, mt: 2, fontWeight: 'fontWeightBold' }}
                />
                <Card sx={{ borderRadius: '5px', backgroundColor: theme.palette.grey[200], padding: 0, mt: 3 }}>
                    {loading ?
                        <Stack gap={2}>
                            {getSkeletons(1, "100%", "56px")}
                            {getSkeletons(7, "100%", "30px")}
                        </Stack>
                        :
                        <Scrollbar>
                            <TableContainer className='overflow-hidden'>
                                <Table>
                                    <ItemListHead
                                        headLabel={props.TABLE_HEAD}
                                        rowCount={itemList[currentEmail]?.length}
                                        pageTitle={`${props.pageTitle}`}
                                    />
                                    <TableBody>
                                        {filteredItems[currentEmail]?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowKey) => {
                                            const stageNameKey = row.StageName;

                                            let status = statusColors[stageNameKey]
                                                ? stageNameKey === "Insufficient Information"
                                                    ? "Insufficient"
                                                    : limitToTwoWords(stageNameKey)
                                                : "Pending pickup";

                                            status = status === "Unclaimed"
                                                ? "Pending pickup"
                                                : status === "On hold"
                                                    ? "On hold"
                                                    : limitToTwoWords(status);

                                            status = (status === "Completed" && row.Product__c === "Spotify")
                                                ? "Placement made"
                                                : (status === "Setting Up Campaign" && row.Product__c === "Spotify")
                                                    ? limitToTwoWords('Setting Up Campaign')
                                                    : limitToTwoWords(status);

                                            status = status === "Refund Request"
                                                ? "Pitching"
                                                : status === "On hold"
                                                    ? "On hold"
                                                    : limitToTwoWords(status);

                                            status = status === "Waiting for"
                                                ? "Pending placement"
                                                : status === "On hold"
                                                    ? "On hold"
                                                    : limitToTwoWords(status);

                                            return (
                                                <>
                                                    <TableRow onClick={() => handleShowMore(row.Id)}
                                                        sx={{ cursor: "pointer", height: "72px" }} hover
                                                        key={`row-${rowKey}-${row.Id}-${row[Object.keys(row)[1]]}`}
                                                        tabIndex={-1} role="checkbox" selected={selected[rowKey]}>
                                                        <TableCell align="left" size="small" sx={{
                                                            borderColor: theme.palette.grey[300],
                                                            fontSize: { xs: 14, md: 17 }
                                                        }} className="date-col">
                                                            {convertDate(row.Order_Date__c)}
                                                        </TableCell>
                                                        {!isOnSmallScreen &&
                                                            <TableCell align="left" size="small" sx={{
                                                                borderColor: theme.palette.grey[300],
                                                                fontSize: 17
                                                            }}>
                                                                {row.InvoiceSamCartOrderNumber__c || "Unknown"}
                                                            </TableCell>}
                                                        {!isOnSmallScreen &&
                                                            <TableCell align="left" size="small" sx={{
                                                                borderColor: theme.palette.grey[300],
                                                                fontSize: 17
                                                            }}>
                                                                {row.SpotifyTrackTitle__c || "-"}
                                                            </TableCell>}
                                                        {!isOnSmallScreen &&
                                                            <TableCell align="left" size="small" sx={{
                                                                borderColor: theme.palette.grey[300],
                                                                fontSize: 17
                                                            }}>
                                                                {row.PackageDetails__c || (row.Package__c && row.Product__c ? `Custom ${row.Product__c} ${row.Package__c}` : "Unknown")}
                                                            </TableCell>}
                                                        <TableCell align="center" size="small" sx={{
                                                            borderColor: theme.palette.grey[300],
                                                            fontSize: { xs: 14, md: 17 }
                                                        }}>
                                                            <Box sx={{
                                                                color: status === "On hold" ? '#000' : '#fff',
                                                                marginLeft: { xs: "auto", md: "0" },
                                                                backgroundColor: statusColors[status],
                                                                width: { xs: 120, md: 145 },
                                                                height: { xs: 20, md: 24 },
                                                                borderRadius: 9
                                                            }}>
                                                                {status || "Unknown"}
                                                            </Box>
                                                        </TableCell>
                                                        {!isOnSmallScreen &&
                                                            <>
                                                                <TableCell align="left" size="small"
                                                                    sx={{ borderColor: theme.palette.grey[300] }}>
                                                                    <Button sx={{
                                                                        color: theme.palette.common.black,
                                                                        fontSize: 17,
                                                                        fontWeight: 400,
                                                                        padding: 0
                                                                    }}>{showMore === row.Id ? "Less" : "More"} details</Button>
                                                                </TableCell>
                                                            </>}
                                                        {isOnSmallScreen &&
                                                            <TableCell align="center" size="small" sx={{
                                                                borderColor: theme.palette.grey[300],
                                                                fontSize: 17
                                                            }}>
                                                                <Box sx={{ mt: 1 }}>
                                                                    {showMore === row.Id ?
                                                                        <Iconify icon="la:angle-down" width={15} />
                                                                        :
                                                                        <Iconify icon="la:angle-right" width={15} />
                                                                    }
                                                                </Box>
                                                            </TableCell>}
                                                    </TableRow>
                                                    {showMore === row.Id &&
                                                        (status !== "Unclaimed" && row.InvoiceSamCartOrderNumber__c ?
                                                            <ShowMoreInfo
                                                                cmName={row.Owner.Name}
                                                                status={row.StageName}
                                                                orderNumber={row.InvoiceSamCartOrderNumber__c}
                                                                orderDate={convertDate(row.Order_Date__c)}
                                                                product={row.Product__c.split(' ')[0]}
                                                                productDetail={row.PackageDetails__c ? row.PackageDetails__c : `Custom ${row.Product__c} ${row.Package__c}`}
                                                                playlists={row.Playlists__r && row.Playlists__r.records}
                                                                videoURLs={row.Video_Links__r && row.Video_Links__r.records}
                                                                campaignUpdate={row.YouGrowPlusDescription__c}
                                                                deliveredViews={row.DeliveredViews ? row.DeliveredViews : row.DeliverdViews__c}
                                                                newFollowers={row.NewFollowers__c}
                                                                newLikes={row.NewLikes__c}
                                                                newComments={row.NewComments__c}
                                                                chatMessages={props.theChatMessages}
                                                                description={row.Description}
                                                                spotifyUrl={row.SpotifySongUrl__c}
                                                                campaignDescription={row.CampaignStatusDescription}
                                                            />
                                                            :
                                                            <Typography sx={{ py: 5 }}>
                                                                {row.InvoiceSamCartOrderNumber__c ? `Your order has been processed in our system but it’s still waiting for one of our campaign managers to start working on your campaign. As soon as someone is available, a campaign manager will get your campaign up and running for you.` : "There is no order number, please contact support to check on your order."}
                                                            </Typography>)
                                                    }
                                                </>
                                            );
                                        })}
                                    </TableBody>
                                    {isNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                    <Paper
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            Not found
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            No results found for &nbsp;
                                                            <strong>&quot;{filterName}&quot;</strong>.
                                                            <br /> Try checking for typos or using complete words.
                                                        </Typography>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}
                                </Table>
                            </TableContainer>
                        </Scrollbar>
                    }
                    {Boolean(axiosError.message.length) && (
                        <Alert severity='error'>{axiosError.message} {axiosError.showLink &&
                            <a href='https://www.yougrowpromo.com/?utm_source=yougrowplus' rel='noreferrer'
                                target='_blank'>here</a>}.</Alert>)}
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={filteredItems[currentEmail]?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        labelRowsPerPage="Rows:"
                    />
                </Card>
            </StyledBox>
        </>
    );
}
