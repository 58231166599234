import React, { useEffect, useMemo, useRef } from "react";
import PropTypes, { number } from "prop-types";
import {useTheme, Box, Typography, TableRow, TableCell, Stack, Grid, Button} from "@mui/material";
import { fShortenNumber } from "../../../utils/formatNumber";
import useResponsive from "../../../hooks/useResponsive";
import Playlists from "./Playlists";
import VideoUrls from "./VideoUrls";
import { useAuth } from "../../../auth/Authentication";
import ManagerUpdate from "./manager-update";
import CampaignStatus from "./campaign-status";
import CTABox from "./cta-box";
import CampaignManager from "./campaign-manager";
import CampaignViews from "./view-amount";
import useGetUrlFromString from "../../../hooks/useGetUrlFromString";
import ClaimTen from "./claim-10-box";
import useConsoleLog from "../../../hooks/useConsoleLog";
import StyledLoadingButton from "../../../theme/artist/overrides/StyledLoadingButton";

ShowMoreInfo.propTypes = {
    deliveredViews: number, 
    newFollowers: number,
    newLikes: number,
    newComments: number,
    cmName: PropTypes.string,
    playlists: PropTypes.array || null,
    videoURLs: PropTypes.array || null,
    status: PropTypes.string,
    product: PropTypes.string,
    productDetail: PropTypes.string,
    orderNumber: PropTypes.string,
    orderDate: PropTypes.string,
    campaignUpdate: PropTypes.string,
    description: PropTypes.string,
    spotifyUrl: PropTypes.string,
    campaignDescription: PropTypes.string,
}

export function checkNameForDupes(name) {
    if (name === 'Sem Buist') {
        const newName = name?.replace(' ', '_');

        return newName?.toString()?.toLowerCase();
    };

    return convertedName(name)
}

export function convertedName(name) {
    const newName = name?.includes(' ') ? name?.split(' ')[0] : name;

    return newName?.toString()?.toLowerCase();
}

export default function ShowMoreInfo({
    deliveredViews, 
    newFollowers,
    newLikes,
    newComments,
    cmName, 
    playlists,
    videoURLs,
    product, 
    productDetail, 
    status, 
    orderNumber, 
    orderDate, 
    campaignUpdate,
    description,
    spotifyUrl,
    campaignDescription
}) {
    const isOnSmallScreen = useResponsive("down", "md", "lg");
    const theme = useTheme();
    const getUrlFromString = useGetUrlFromString();
    const auth = useAuth();
    const infOverview = useRef(null);
    const playOverview = useRef(null);
    const managerUpdateRef = useRef(null);
    const cStatusRef = useRef(null);
    const log = useConsoleLog;

    useEffect(() => {
        const allEls = [
            infOverview.current,
            managerUpdateRef.current,
            playOverview.current,
            cStatusRef.current
        ];


        if (Boolean(playOverview.current)) {
            playOverview.current.style.height = '178px';
        }

        let tallestHeight = 0;
        allEls.forEach(el => {
            if (el && el.offsetHeight > tallestHeight) {
                tallestHeight = el.offsetHeight;
            }
        });

        if (tallestHeight < 173) {
            tallestHeight = 173;
        }

        allEls.forEach(el => {
            if (el) {
                el.style.height = `${tallestHeight}px`;
            }
        });

        log("cStatusEl");
        log(cStatusRef);
    }, [cStatusRef.current]);

    return (
        <TableRow>
            <TableCell colSpan={6} width="100%" className="dashboard-table-cell"> 
                    {isOnSmallScreen &&
                    <Box sx={{marginBottom: 2}}>
                        <Grid container justifyContent="space-between" spacing={2}>
                            <Grid item xs={6}>
                                <Stack>
                                    <Typography sx={{fontSize: "17px", color: theme.palette.grey[600]}}>Order number</Typography>
                                    <Typography>
                                        {orderNumber}
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Stack>
                                    <Typography textAlign="right" sx={{fontSize: "17px", color: theme.palette.grey[600]}}>Package name</Typography>
                                    <Typography textAlign="right">
                                            {productDetail}
                                    </Typography>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                    }     
                    <Box>      
                        <Stack rowGap={{xs: 2, md: 4}}>
                            {status !== "Unclaimed" && 
                                <Grid container justifyContent="space-between">
                                    <CampaignManager
                                        cmName={cmName}
                                    />
                                </Grid>
                            }
                            {productDetail?.toLowerCase().startsWith("meta ads") && (
                                <Stack container sx={{ my: 2 }}>
                                    <Typography className="extra-info-title showmore-title" variant="body1">
                                        Do you like your campaign’s results?
                                    </Typography>
                                    <StyledLoadingButton
                                        onClick={() => {window.open('https://www.yougrowpromo.com/pages/meta-ads-phase-2', '_blank')}}
                                        sx={{ display: 'flex', gap: 12, fontSize: 16, fontWeight: 300, paddingLeft: 28, paddingRight: 28, width: 'fit-content', marginTop: 12 }}
                                    >
                                        Upgrade your campaign
                                        <img src="/assets/icons/lightning.svg" alt="Lightning" />
                                    </StyledLoadingButton>
                                </Stack>
                            )}
                            <Grid container justifyContent="space-between" className="orders-sm-section first" spacing={2} rowGap={2}>
                                <Grid item md={6} xs={12}>
                                    {deliveredViews > 0 || newFollowers > 0 || newLikes > 0 || newComments > 0 ?
                                        <Box className="h-100">
                                            <Grid container spacing={1} justifyContent={'space-between'}>
                                                {deliveredViews > 0 &&
                                                    <Grid item md={3} xs={6}>
                                                        <CampaignViews
                                                            ref={cStatusRef}
                                                            title={'Delivered views'}
                                                            views={deliveredViews}
                                                        />
                                                    </Grid>
                                                }
                                                {newFollowers > 0 &&
                                                    <Grid item md={3} xs={6}>
                                                        <CampaignViews
                                                            ref={cStatusRef}
                                                            title={'New followers'}
                                                            views={newFollowers}
                                                        />
                                                    </Grid>
                                                }
                                                {newLikes > 0 &&
                                                    <Grid item md={3} xs={6}>
                                                        <CampaignViews
                                                            ref={cStatusRef}
                                                            title={'New likes'}
                                                            views={newLikes}
                                                        />
                                                    </Grid>
                                                }
                                                {newComments > 0 &&
                                                    <Grid item md={3} xs={6}>
                                                        <CampaignViews
                                                            ref={cStatusRef}
                                                            title={'New comments'}
                                                            views={newComments}
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Box>
                                    :
                                        <CampaignStatus
                                            ref={cStatusRef}
                                            status={campaignDescription}
                                        />
                                    }
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    {
                                        deliveredViews > 0 || newFollowers > 0 || newLikes > 0 || newComments > 0 ?
                                            <CampaignStatus
                                                ref={cStatusRef}
                                                status={campaignDescription}
                                            />
                                        :
                                        <ManagerUpdate
                                            ref={managerUpdateRef}
                                            update={campaignUpdate}
                                        />
                                    }
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="space-between" className="orders-sm-section" spacing={2}>
                                <Grid item md={6}>
                                    <Stack rowGap={{xs: 2, md: 4}}>
                                        {playlists &&
                                            <Box>
                                                <Typography className="showmore-title">Playlist overview</Typography>
                                                <Playlists playlists={playlists} ref={playOverview}/>
                                            </Box>
                                        }
                                        {videoURLs &&
                                            <Box>
                                                <Typography className="showmore-title">Video overview</Typography>
                                                <VideoUrls type="Video URL" ref={infOverview} playlists={videoURLs}/>
                                            </Box>
                                        }
                                        <Box>
                                            <CTABox/>
                                        </Box>
                                    </Stack>
                                </Grid>
                                <Grid item md={6}>
                                    <Stack rowGap={{xs: 2, md: 4}}>
                                        {
                                            (deliveredViews > 0 || newFollowers > 0 || newLikes > 0 || newComments > 0) &&
                                            <>
                                                <ManagerUpdate
                                                    update={campaignUpdate}
                                                    ref={managerUpdateRef}
                                                />
                                            </>
                                        }
                                        <ClaimTen/>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
            </TableCell>
        </TableRow>
    )
}